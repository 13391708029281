type ItemType = 'CAR' | 'CASTLE' | 'CREATURE' | 'LAND' | 'DRAGON' | 'AVATAR'

export default function getCurrency(itemType: ItemType) {
  const currencies = {
    CAR: 'TCG2',
    CASTLE: 'TCG2',
    CREATURE: 'TCG2',
    AVATAR: 'TCG2',
    LAND: 'BNB',
  };

  if (currencies[itemType]) {
    return currencies[itemType];
  }

  return 'BNB';
}
