var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"payment-methods-list"},_vm._l((_vm.paymentMethods),function(method,idx){return _c('li',{key:method.id,class:{
      'payment-methods-list__item': true,
      'payment-methods-list__item--color--blue': method.color === 'blue',
      'payment-methods-list__item--color--orange': method.color === 'orange',
      'payment-methods-list__item--selected': method.selected,
    },attrs:{"title":""},on:{"click":function($event){return _vm.togglePaymentMethod(idx)}}},[_c('div',{class:{
        'payment-methods-list__item-background': true,
        'payment-methods-list__item-background--color--blue': method.color === 'blue',
        'payment-methods-list__item-background--color--orange': method.color === 'orange',
        'payment-methods-list__item-background--selected': method.selected,
      }}),_c('div',{staticClass:"payment-methods-list__content"},[_c('div',{staticClass:"payment-methods-list__img-wrp"},[_c('img',{staticClass:"payment-methods-list__img",attrs:{"src":method.img}})]),_c('div',{staticClass:"payment-methods-list__text"},[_c('h3',{staticClass:"payment-methods-list__name"},[_vm._v(_vm._s(method.name))]),_c('span',{staticClass:"payment-methods-list__desc"},[_vm._v(_vm._s(method.text))])])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }