var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"selling"},[(!_vm.item.locked)?_c('header',{staticClass:"selling__header"},[_c('h1',{staticClass:"selling__title"},[_vm._v(" You can sell "),_c('span',{class:[
            'selling__item-name',
            _vm.item.item.title ]},[_vm._v(" “"+_vm._s(_vm.item.item.title)+"” ")])])]):_vm._e(),_c('div',{staticClass:"selling__content"},[(!_vm.item.locked)?_c('div',{staticClass:"selling__text"},[_c('p',[_vm._v(" You can either sell “"+_vm._s(_vm.item.item.title)+"” for fixed price or put it for auction ")]),_c('p',[_vm._v(" Make a choice ")])]):_vm._e(),(_vm.item.locked)?_c('div',{staticClass:"selling__warning"},[_c('img',{staticClass:"selling__warning-img",attrs:{"src":"https://d1ak9kdvdopy8s.cloudfront.net/items/whirlpool.webp","alt":""}}),_c('span',{staticClass:"selling__warning-text"},[_vm._v(" This item has recently been transferred, please wait "),_c('countdown',{attrs:{"end-time":new Date(_vm.item.unlocked_at).getTime()},scopedSlots:_vm._u([{key:"process",fn:function(ref){
          var timeObj = ref.timeObj;
return [_c('div',{class:{
                'selling__timer': true,
                'selling__timer--almost--expired': timeObj.leftTime <= 3600000
              }},[_c('span',{staticClass:"selling__timer-item"},[_vm._v(" "+_vm._s(timeObj.d)+"d ")]),_c('span',{staticClass:"selling__timer-item"},[_vm._v(" "+_vm._s(timeObj.h)+"h ")]),_c('span',{staticClass:"selling__timer-item"},[_vm._v(" "+_vm._s(timeObj.m)+"m ")]),_c('span',{staticClass:"selling__timer-item"},[_vm._v(" "+_vm._s(timeObj.s)+"s ")])])]}}],null,false,3613599575)})],1)]):_vm._e(),(!_vm.item.locked)?_c('div',{staticClass:"selling__content-btns"},[_c('button',{staticClass:"selling__sell-btn",on:{"click":function($event){return _vm.showPopup(0)}}},[_vm._v(" Fixed Price ")]),_c('button',{staticClass:"selling__auction-btn",on:{"click":function($event){return _vm.showPopup(1)}}},[_vm._v(" Auction ")])]):_vm._e()]),_c('transition',{attrs:{"name":"popup-appearance"}},[(_vm.popupVisible)?_c('PopupWrapper',{attrs:{"maxWidth":_vm.popupWidth},on:{"close":function($event){return _vm.closePopup()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_vm.popupContentComponent,{tag:"component",attrs:{"item":_vm.item,"preloadComponentIdx":_vm.preloadComponentIdx,"popupActionTitle":_vm.popupTitle,"popupActionText":_vm.popupText,"popupActionAmount":_vm.popupAmount,"popupActionImg":_vm.popupImg,"popupActionAmountVisible":_vm.popupAmountVisible,"popupActionCurrency":_vm.currency},on:{"success":function($event){return _vm.successPopup()},"fail":function($event){return _vm.failPopup()},"close":function($event){return _vm.closePopup()}}})]},proxy:true}],null,false,3728406769)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }