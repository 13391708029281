import { render, staticRenderFns } from "./ItemDetailInfo.vue?vue&type=template&id=7446339a&scoped=true&"
import script from "./ItemDetailInfo.vue?vue&type=script&lang=ts&"
export * from "./ItemDetailInfo.vue?vue&type=script&lang=ts&"
import style0 from "./ItemDetailInfo.vue?vue&type=style&index=0&id=7446339a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7446339a",
  null
  
)

export default component.exports